:root {
  --mint-cream: #f1f7edff;
  --dark-slate-gray: #243e36ff;
  --cambridge-blue: #7ca982ff;
  --nyanza: #e0eec6ff;
  --old-gold: #c2a83eff;

  --cat-pic-height: 200px;

}

.App {
  height: 100vh;
  background-color: var(--dark-slate-gray);
  display: flex;
  flex-direction: column;
  align-items: center;

  color: var(--mint-cream);
  font-size: calc(10px + 2vmin);


}

.title {
  color: var(--cambridge-blue);
  margin-top: 8vh;
  margin-bottom: 1vh;
}

.splashText {
  font-size: calc(10px + 1vmin);
  margin-top: 0;
  color: var(--cambridge-blue)
}


.statsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.totalKlappsContainer {
  display: flex;
  
  
}

.totalKlappsText {
  margin: 0.5vh;
}

.totalKlappsNumber {
  color: var(--old-gold);
}

.catContainer {
  height: var(--cat-pic-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clickableCat {
  height: var(--cat-pic-height);

  
}

.clickableCat[squash='1'] {
  animation: Squash 0.3s cubic-bezier(0.03, 0.88, 0.58, 1) 0s;
}

@keyframes Squash {
  0% {
    transform: scaleY(1.0) scaleX(1.0);

    transform-origin: bottom;
  }

  50% {
    transform: scaleY(0.5) scaleX(1.3);
  
    transform-origin: bottom;
    
  }

  100% {
    transform: scaleY(1.0) scaleX(1.0);

    transform-origin: bottom;
  }
}

